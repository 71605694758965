import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetKsatriyaSkinParts from "src/api/mailAPI/attachmentsApi/getSkinPartsApi";


function* handleGetSkinParts() {
  try {
    const skinParts = yield call(fetchGetKsatriyaSkinParts);
    yield put({ type: "GET_SKIN_PARTS_SUCCESS", skinParts: skinParts });
  } catch (err) {
    yield put({ type: "GET_SKIN_PARTS_FAILED", message: err.message });
  }
}

function* watcherSkinPartsSaga() {
  yield takeLatest("GET_SKIN_PARTS_REQUEST", handleGetSkinParts);
}

export default watcherSkinPartsSaga;