import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './redux/reducer'
import rootSaga from './redux/saga'

const sagaMiddleware = createSagaMiddleware ()
// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

const store = createStore(rootReducer,composeWithDevTools (applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(rootSaga)
export default store