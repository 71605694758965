const url = "/api/box/getBoxes";
const header = {
            headers: {
                method: "GET",
                Authorization: 'Bearer ' + localStorage.getItem('auth'),
                 /* "Access-Control-Allow-Origin": "*", */
               
            }
        }

const fetchGetBoxes = async () => {
  return await fetch(url, header)
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
};

export default fetchGetBoxes;