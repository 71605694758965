const initialState = {
    frames: [],
    loading: false,
    error: null,
  };
  
  const frames = (state = initialState, action) => {
    switch (action.type) {
      case "GET_FRAMES_REQUEST":
        return { ...state, loading: true };
      case "GET_FRAMES_SUCCESS":
        return { ...state, loading: false, frames: action.frames };
      case "GET_FRAMES_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default frames;