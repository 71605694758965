import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetBoxes from "src/api/mailAPI/attachmentsApi/getBoxesApi";

function* handleGetBoxes() {
  try {
    const boxes = yield call(fetchGetBoxes);
    yield put({ type: "GET_BOXES_SUCCESS", boxes: boxes });
  } catch (err) {
    yield put({ type: "GET_BOXES_FAILED", message: err.message });
  }
}

function* watcherBoxesSaga() {
  yield takeLatest("GET_BOXES_REQUEST", handleGetBoxes);
}

export default watcherBoxesSaga;