const url = "api/user/getAllUsers";
const header = {
            headers: {
                method: "GET",
                Authorization: 'Bearer ' + localStorage.getItem('auth'),
                 /* "Access-Control-Allow-Origin": "*", */
               
            }
        }

const fetchGetUsers = async () => {
  try {
    const response = await fetch(url, header);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export default fetchGetUsers;