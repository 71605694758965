const { currentInfoApi } = require("src/api/currentinfo/currentInfoApi");
const {
  currentInfoTypes,
} = require("src/redux/action/types/currentInfoTypes/currentInfoTypes");
const { takeLatest, put } = require("redux-saga/effects");

function* currentInfoSaga() {
  yield takeLatest(currentInfoTypes.GET_INFO, getInfo);
}

function* getInfo(action) {
  try {
    const response = yield currentInfoApi();
    yield put({
      type: currentInfoTypes.GET_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: currentInfoTypes.GET_INFO_FAILED,
      payload: error,
    });
  }
}

export default currentInfoSaga;
