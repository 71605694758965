import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetSkins from "src/api/mailAPI/attachmentsApi/getSkinsApi";

function* handleGetSkins() {
  try {
    const skins = yield call(fetchGetSkins);
    yield put({ type: "GET_SKINS_SUCCESS", skins: skins });
  } catch (err) {
    yield put({ type: "GET_SKINS_FAILED", message: err.message });
  }
}

function* watcherSkinsSaga() {
  yield takeLatest("GET_SKINS_REQUEST", handleGetSkins);
}

export default watcherSkinsSaga;