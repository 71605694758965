import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetitems from "src/api/mailAPI/attachmentsApi/getItemsApi";

function* handleGetItems() {
  try {
    const items = yield call(fetchGetitems);
    yield put({ type: "GET_MISC_ITEMS_SUCCESS", items: items });
  } catch (err) {
    yield put({ type: "GET_MISC_ITEMS_FAILED", message: err.message });
  }
}

function* watcherItemsSaga() {
  yield takeLatest("GET_MISC_ITEMS_REQUEST", handleGetItems);
}

export default watcherItemsSaga;