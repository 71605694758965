const initialState = {
    items: [],
    loading: false,
    error: null,
  };
  
  const items = (state = initialState, action) => {
    switch (action.type) {
      case "GET_MISC_ITEMS_REQUEST":
        return { ...state, loading: true };
      case "GET_MISC_ITEMS_SUCCESS":
        return { ...state, loading: false, items: action.items };
      case "GET_MISC_ITEMS_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default items;