import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import players from "./mailReducer/getPlayersReducer.";
import ksatriyas from "./mailReducer/getKsatriyasReducer";
import ksatriyaFragments from "./mailReducer/getKsatriyaFragmentReducer";
import boxes from "./mailReducer/getBoxesReducer";
import skins from "./mailReducer/getSkinsReducer";
import skinParts from "./mailReducer/getSkinPartsReducer";
import runes from "./mailReducer/getRunesReducer";
import frames from "./mailReducer/getFramesReducer";
import avatars from "./mailReducer/getAvatarsReducer";
import userManagementReducer from "./userManagementReducer";
import items from "./mailReducer/getItemsReducer";
import getNewsReducer from "./newsmanagement/getNewsReducer";
import currentInfoReducer from "./currentInfoReducer/currentInfoReducer";
import rolesManagementReducer from "./rolesmanagement/rolesManagementReducer";
import users from "./userManagementReducer";
import roles from "./rolesmanagement/rolesManagementReducer";
import vtemplates from "./generatevoucherReducer/getVoucherTemplateReducer";
import getMaintenanceReducer from "./maintenancemanagement/getMaintenanceReducer";

export default combineReducers({
  loginReducer,
  players,
  ksatriyas,
  boxes,
  skins,
  runes,
  frames,
  avatars,
  getNewsReducer,
  items,
  currentInfoReducer,
  rolesManagementReducer,
  userManagementReducer,
  users,
  roles,
  vtemplates,
  getMaintenanceReducer,
  skinParts,
  ksatriyaFragments,
});
