const initialState = {
    roles: [],
    loading: false,
    error: null,
  };
  
  const roles = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ROLES_REQUEST":
        return { ...state, loading: true };
      case "GET_ROLES_SUCCESS":
        return { ...state, loading: false, roles: action.roles };
      case "GET_ROLES_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default roles;