import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetUsers from "src/api/userManagementApi";

function* handleGetUsers() {
  try {
    const users = yield call(fetchGetUsers);
    yield put({ type: "GET_USERS_SUCCESS", users: users });
  } catch (err) {
    yield put({ type: "GET_USERS_FAILED", message: err.message });
  }
}

function* watcherUsersSaga() {
  yield takeLatest("GET_USERS_REQUEST", handleGetUsers);
}

export default watcherUsersSaga;