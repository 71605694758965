const initialState = {
    skins: [],
    loading: false,
    error: null,
  };
  
  const skins = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SKINS_REQUEST":
        return { ...state, loading: true };
      case "GET_SKINS_SUCCESS":
        return { ...state, loading: false, skins: action.skins };
      case "GET_SKINS_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default skins;