const initialState = {
    ksatriyaFragments: [],
    loading: false,
    error: null,
  };
  
  const ksatriyaFragments = (state = initialState, action) => {
    switch (action.type) {
      case "GET_KSATRIYA_FRAGMENT_REQUEST":
        return { ...state, loading: true };
      case "GET_KSATRIYA_FRAGMENT_SUCCESS":
        return { ...state, loading: false, ksatriyaFragments: action.ksatriyaFragments };
      case "GET_KSATRIYA_FRAGMENT_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default ksatriyaFragments;