import { GMTOOLS_DOMAIN, apiDomains } from "src/constants/domains";

function getApiDomain() {
  const domain = window.location.hostname;

  if (apiDomains.hasOwnProperty(domain)) {
    return `https://${apiDomains[domain]}`;
  } else {
    return `https://${apiDomains[GMTOOLS_DOMAIN.DEV]}`;
  }
}

export default getApiDomain;
