const { maintenanceManagementApi } = require("src/api/maintenancemanagement/maintenanceManagementApi");
const { getMaintenanceTypes } = require("src/redux/action/types/maintenancemanagement/getMaintenanceTypes");
const { takeLatest,put } = require('redux-saga/effects');

function* getMaintenanceSaga (){
    yield takeLatest (getMaintenanceTypes.GET_MAINTENANCE,getMaintenance)
}

function* getMaintenance (action){
    try {
        const response = yield maintenanceManagementApi()
        // console.log('response saga',response.data)
        yield put ({
            type:getMaintenanceTypes.GET_MAINTENANCE_SUCCESS,payload:response.data
        })
    } catch (error) {
        yield put ({
            type:getMaintenanceTypes.GET_MAINTENANCE_FAILED,payload:error
        })
    }
}

export default getMaintenanceSaga;