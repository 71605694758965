export const GMTOOLS_DOMAIN = {
  DEV: "dev-gm.lokapala.games",
  STG: "stg-gm.lokapala.games",
  PROD: "gm.lokapala.games",
};

const LOKAPALA_DOMAIN = {
  DEV: "dev-api.lokapala.games",
  STG: "stg-api.lokapala.games",
  PROD: "api.lokapala.games",
};

export const apiDomains = {
  [GMTOOLS_DOMAIN.DEV]: LOKAPALA_DOMAIN.DEV,
  [GMTOOLS_DOMAIN.STG]: LOKAPALA_DOMAIN.STG,
  [GMTOOLS_DOMAIN.PROD]: LOKAPALA_DOMAIN.PROD,
};
