import { loginTypes } from "../action/types/loginTypes";

const initialState = {
  isLoading: false,
  // isError:false,
  data: [],
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case loginTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    }
    case loginTypes.LOGIN_FAILED: {
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default loginReducer;
